import React from "react";
//import Figure from "./Figure";
import MainImage from "./MainImage";
import ReactPlayer from "react-player";
//import InstagramEmbed from "react-instagram-embed";



const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};


const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    videoEmbed: ({ node }) => <ReactPlayer className="videoEmbedded" url={node.url} controls />,
    /*instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="instagramEmbedded" />;
    },*/
  }
};

export default serializers;
