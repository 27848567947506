import React from "react";

import styles from "./footerSimple.module.css";
import BlockContent from "./sanity/block-content";

const FooterSimple = ({
    siteTitle,
    siteFooterTextFirst,
    siteFooterTextSecond,
    siteFooterTextThird,
  }) => (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.footerInnerGrid}>
  
          <div className={styles.gridItem}>
            <BlockContent blocks={siteFooterTextFirst} />
          </div>
  
          <div className={styles.gridItem}>
            <BlockContent blocks={siteFooterTextSecond} />
          </div>

          <div className={styles.gridItem}>
            <BlockContent blocks={siteFooterTextThird} />
          </div>
  
        </div>
      </div>
    </footer>
  );
  
  export default FooterSimple;