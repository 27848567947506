import React from "react";

import styles from "./container.module.css";

export const Container = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

export const ContainerComponent = ({ children }) => {
  return <div className={styles.containerComponent}>{children}</div>;
};

export const ContainerComponentResponsive = ({ children }) => {
  return <div className={styles.containerComponentResponsive}>{children}</div>;
};
